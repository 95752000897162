import React from "react";
import { Toaster } from "react-hot-toast";

import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import Root from "./components/Root";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Portfolio from "./pages/Portfolio";
import ContactUs from "./pages/ContactUs";
import PageNotFound from "./pages/PageNotFound";
import ThemeContextProvider from "./context/ThemeContext";
import ThemeSwitch from "./components/ThemeSwitch";

const appRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Root />} errorElement={<PageNotFound />}>
      <Route index element={<Home />} />
      <Route path='/sobre-nosotros' element={<AboutUs />} />
      <Route path='/portafolio' element={<Portfolio />} />
      <Route path='/contacto' element={<ContactUs />} />
      <Route path='*' element={<PageNotFound />} />
    </Route>
  )
);

function App() {
  return (
    <ThemeContextProvider>
      <Toaster position='top-right' />
      <RouterProvider router={appRouter} />
      <ThemeSwitch />
    </ThemeContextProvider>
  );
}

export default App;
