import Intro from "../components/Home/Intro";
import Features from "../components/Home/Features";
import Services from "../components/Home/Services";

const Home = () => {
  return (
    <>
      <Intro />
      <Features />
      <Services />
    </>
  );
};

export default Home;
