const Footer = () => {
  function getCurrentYear() {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }
  return (
    <footer>
      <p className='mt-6 text-sm text-slate-500 sm:mt-0'>
        Copyright © {getCurrentYear()} · Soluciones iO, SLU · Estepona, Malaga,
        España
      </p>
    </footer>
  );
};

export default Footer;
