export const projectsData = [
  {
    title: "La Furia Travel Club",
    description:
      "Aplicación web que consiste en enviar los mejores precios de vuelos desde el aeropuerto de salida que el usuario escoge. Hecha con NextJS (React) y el backend hace el scraping a Google Flights para encontrar las mejores ofertas.",
    tags: ["React", "NextJS", "Tailwind CSS", "shadcn"],
    imageUrl: "projects/lafuriatravel-club.webp",
    link: "https://lafuriatravel.club",
  },
  {
    title: "Portafolio Ricardo Rodriguez",
    description:
      "Portafolio de desarrollador web hecha en React con efectos usando Framer Motion. Traducción y tema oscuro.",
    tags: ["React", "Framer Motion", "Tailwind CSS"],
    imageUrl: "projects/portfolio-ricardo.webp",
    link: "https://ricardo-rodriguez-portafolio.vercel.app/",
  },
  {
    title: "App de Facturación en Ruby on Rails",
    description:
      "Aplicación web de facturación para autónomos o pequeñas empresas. Hecha en Ruby on Rails con el frontend mejorado con Stimulus y AlpineJS. Emite las facturas en PDF para luego imprimir o enviar por correo electrónico.",
    tags: ["Ruby on Rails", "Stimulus", "AlpineJS", "Tailwind CSS"],
    imageUrl: "projects/invoicing-rails.webp",
    link: "https://fact.solucionesio.es",
  },
  {
    title: "Los Flamingos, Marbella",
    description:
      "El objetivo de este portal era presentar las mejores ofertas de la urbanización Los Flamingos, y utilizar métodos SEO y Google Ads para hacer un mercadeo mucho más específico, especialmente para clientes británicos.",
    tags: ["WordPress", "Genesis", "Resales"],
    imageUrl: "projects/losflamingos.jpg",
  },
  {
    title: "MLeon Pro",
    description:
      "Blog para una consultora de inversión en criptomonedas, incluyendo una sección para informar de los próximos cursos.",
    tags: ["WordPress", "Genesis"],
    imageUrl: "projects/mleon-pro.jpg",
  },
  {
    title: "Long Term Rentals Marbella",
    description:
      "Portal de alquileres para la empresa inmobiliaria One Marbella en la Costa del Sol, España. El objetivo de este portal era presentar las mejores ofertas en alquileres de larga estancia y utilizar métodos SEO y Google Ads para hacer un mercadeo mucho más específico, especialmente para clientes británicos.",
    tags: ["WordPress", "Genesis", "Resales"],
    imageUrl: "projects/longtermrentals.jpg",
  },
  {
    title: "Jacksonville Dutch Croquettes",
    description:
      "Una empresa que distribuye croquetas holandesas elaboradas por Voorn Croquettes. Diseño original utilizando Genesis Framework en WordPress. Algunas mejoras de usuario en la selección de precios al volumen con javascript.",
    tags: ["WordPress", "WooCommerce", "Genesis", "Stripe", "MailChimp"],
    imageUrl: "projects/jax-croquettes.jpg",
  },
  {
    title: "YupiUSA",
    description:
      "Esta es la distribuidora de los equipos de Suministros Yupi en estado de Florida, EEUU.",
    tags: ["WordPress", "WooCommerce", "MailChimp", "JavaScript"],
    imageUrl: "projects/yupiusa.jpg",
  },
  {
    title: "Suministros Yupi, C.A.",
    description:
      "Empresa líder en venta y distribución de equipos y materiales de limpieza en Venezuela. La extensión WooCommerce hubo que ajustarla y moldearla a que funcione en modo catálogo para que los potenciales clientes puedan pedir presupuesto de los equipos en una lista.",
    tags: ["WordPress", "WooCommerce", "MailChimp", "JavaScript"],
    imageUrl: "projects/suministrosyupi.jpg",
  },
  {
    title: "Re-diseño MarbellaBanus",
    description:
      "El cliente pidió un refrescar su página web e incorporar un Blog. Mismas características que la original, pero incluyendo mejores textos y iniciando un Blog para atraer más tráfico.",
    tags: ["WordPress", "WP Casa", "MailChimp", "Google Ads"],
    imageUrl: "projects/redesign-marbellabanus.jpg",
  },
  {
    title: "Stars Skincare",
    description:
      "Spa de cuidado y belleza estético en Boca Ratón, Florida, EEUU. Construida con WordPress, WooCommerce, y Stripe para procesar pagos. Se creó lista de MailChimp, y Google Ads para marketing. Adicionalmente fuimos consultores para la configuración y puesta en marcha de Google Mail y Docs.",
    tags: ["WordPress", "WooCommerce", "Stripe", "MailChimp", "Google Ads"],
    imageUrl: "projects/starsskincare-com.jpg",
  },
  {
    title: "Voorn Croquettes",
    description:
      "Una empresa que elabora y vende croquetas holandesas en los EEUU. Página construida con WordPress, WooCommerce, y Stripe como pasarela de pago. Utiliza un tema propio, diseñado según los requerimientos del cliente. También se creó una lista de correos en MailChimp para marketing post-venta. Finalmente fuimos consultores para diseñar el flujo de trabajo para el procesamiento de los pedidos, ya que las croquetas había que enviarlas congeladas.",
    tags: ["WordPress", "WooCommerce", "Stripe", "MailChimp"],
    imageUrl: "projects/voorncroquettes-com.jpg",
  },
  {
    title: "IQ Board Venezuela",
    description:
      "Web de presentación de una sola página para el distribuidor de pantallas interactivas de la marca IQBoard para Venezuela.",
    tags: ["HTML5", "CSS3", "JavaScript"],
    imageUrl: "projects/focus-tech-com-ve.jpg",
  },
  {
    title: "Focus Tech, C.A.",
    description:
      "Catálogo para empresa integradora de tecnología para presentaciones. Elaborada con WordPress y plantilla propia. Se buscó sencillez y usabilidad.",
    tags: ["WordPress", "CSS3"],
    imageUrl: "projects/focus-tech-com-ve.jpg",
  },
  {
    title: "Agencia Inmobiliaria Marbella Banus",
    description:
      "Uno de nuestros primeros proyectos. Construida con HTML, jQuery, y Bootstrap. En el backend utilizaba un script hecho en PHP para recibir los listados de las propiedades en formato XML desde la API de Resales, que es una proveedora de bases de datos para inmobiliarias en la zona de la Costa del Sol, sur de España. También se programó un script para sincronizar una base dato muy antigua en Francia con algunos listados de la Costa del Sol.",
    tags: ["HTML5", "CSS3", "jQuery", "PHP", "Bootstrap"],
    imageUrl: "projects/marbellabanus-com.jpg",
  },
];
