import React from "react";
import { projectsData } from "../lib/data";
import Project from "../components/Project";

const Portfolio = () => {
  return (
    <>
      <section className='flex flex-col items-center p-4 sm:p-8 lg:p-12'>
        <div>
          {projectsData.map((project, index) => (
            <React.Fragment key={index}>
              <Project {...project} />
            </React.Fragment>
          ))}
        </div>
      </section>
    </>
  );
};
export default Portfolio;
