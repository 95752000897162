import { FaUserAstronaut } from "react-icons/fa";
import { GiGreatPyramid, GiSpaceShuttle } from "react-icons/gi";

const Features = () => {
  return (
    <section
      className='pb-14 pt-20 sm:pb-20 sm:pt-32 lg:pb-32 dark:bg-black'
      aria-label='Razones para contratarnos'
    >
      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
        <div id='features' className='mx-auto max-w-2xl md:text-center'>
          <h2>Desarrollo Práctico y Efectivo</h2>
          <p className='mt-4'>
            Potenciamos tu presencia en línea con diseño web de vanguardia y
            estrategias digitales efectivas. ¡Haz que tu negocio brille en la
            web con nuestro equipo de expertos en marketing y desarrollo web!
            Contáctenos hoy mismo para un análisis personalizado.
          </p>
        </div>
        <div className='mt-10 md:mt-20 lg:block'>
          <div
            className='grid md:grid-cols-3 gap-x-8 gap-y-5'
            role='tablist'
            aria-orientation='horizontal'
          >
            <div className='relative'>
              <div className='w-9'>
                <GiSpaceShuttle size={50} />
              </div>
              <h3>Internet, la frontera final</h3>
              <p className='mt-4'>
                Ofrecemos páginas web que están diseñadas para convertir sus
                ideas y negocio en un retorno de inversión tangible y medible.
                Diseñamos estrategias para que su negocio pueda cosechar
                resultados en la nueva herramienta de venta: Internet.
              </p>
            </div>
            <div className='relative'>
              <div className='w-9'>
                <FaUserAstronaut size={50} />
              </div>
              <h3>Nuestros clientes, nuestros socios</h3>
              <p className='mt-4'>
                Seremos tu socio en todo el proceso de planificación y
                consultoría. Le ayudaremos a cumplir sus objetivos ya sea con el
                lanzamiento de una nueva página web o actualizar una existente.
                Al igual que con su estrategia de marketing en la Web, dónde le
                ayudaremos a optimizar su Retorno de Inversión.
              </p>
            </div>
            <div className='relative'>
              <div className='w-9'>
                <GiGreatPyramid size={50} />
              </div>
              <h3>Tres claves para el éxito de un proyecto:</h3>
              <ol className='list-decimal mt-4 ml-5 text-md text-slate-600 dark:text-slate-400'>
                <li>Generar más tráfico de calidad.</li>
                <li>Convertir más visitantes en clientes.</li>
                <li>Mantener y crecer esa relación.</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
